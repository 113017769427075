<template>
  <div class="module-container">
    <div class="preview-wrap">
      <div class="notice-wrap">
        <div class="notice-content notice-common-wrap">
          <p>通用说明:请确保每个模块都有功能图的展示，否则会出现某一个模块查询不到内容的情况，点击右侧的图可以大图查看模板的示例图，功能模块生成以后需要重启后端服务</p>
        </div>
        <div class="notice-content notice-model-wrap">
            <p v-if="activeName==='0'">已装模板说明:如果后续使用代码生成工具，尽量错开表的名称，可能会出现代码被重写覆盖的情况</p>
            <p v-if="activeName==='1'">登录模板说明:登录模板只展示了登录的样式，使用登录模板会同时更改注册的样式，登录和注册都需要图片，图片名称不要更改</p>
            <p v-if="activeName==='2'">前台模板说明:如果前台模板的(模块依赖)不为空，那么使用的时候就需要安装对应的功能模板，否则可能会导致系统错误，前台路由显示为"是"，那么他就可以在前台上方列表框里直接点击切换</p>
            <p v-if="activeName==='3'">功能模板说明:主要以后端代码为主，如果后台管理显示为"是",那么也会生成对应的后台管理模块，再次使用后端模板会将原来的后端代码和数据库数据一同清空，谨慎操作</p>
        </div>
      </div>
      <div class="main-wrap">
        <div class="module-cover">
          <el-image v-if="currentModule.path" fit="cover" :src="require('../../templates/module/type'+currentModule.type+'/'+currentModule.path+'/template.png')" :preview-src-list="[require('../../templates/module/type'+currentModule.type+'/'+currentModule.path+'/template.png')]"> </el-image>
          <img class="empty-cover" v-else :src="require('../assets/svg/empty.svg')"> </img>
        </div>
      </div>
      <div class="info-wrap">
        <div class="info-list">
          <div class="title">模板名称：{{currentModule.name || '--'}}</div>
          <div class="desc">
            模板简介：{{currentModule.info || '--'}}
          </div>
          <div class="module-info">
            <div class="info-row">
              <div class="info-item">模块类型：{{moduleTypes[currentModule.type] || '--'}}</div>
              <div class="info-item">模块路径：{{currentModule.path || '--'}}</div>
              <div class="info-item">模块依赖：{{currentModule.dependency || '--'}}</div>
            </div>
            <div class="info-row">
              <div class="info-item">前台路由：{{currentModule.front===1 ? '是':'否'}}</div>
              <div class="info-item">后台管理：{{currentModule.back===1 ? '是':'否'}}</div>
            </div>
          </div>
        </div>
        <div class="btn-wrap">
          <el-button class="btn-submit" @click="useModule">安装该模板</el-button>
        </div>
      </div>
    </div>
    <div class="module-wrap">
      <el-tabs v-model="activeName" tab-position="left" @tab-click="loadModules">
        <el-tab-pane v-for="(item, index) in moduleTypes" :key="index" :name="index + ''" :label="item" >
          <div class="module-content-wrap">
            <div class="search-wrap">
              <el-input size="small" v-model="name" placeholder="支持模板名称搜索"/>
              <el-button type="primary" @click="loadModules">搜索</el-button>
              <el-button @click="reset">重置</el-button>
            </div>
            <div v-if="moduleList.length > 0" class="module-list">
              <div v-for="item in moduleList" :key="item.id" class="module-item" @click="changeModule(item)">
                <div class="module-cover">
                  <img :src="require('../../templates/module/type'+item.type+'/'+item.path+'/template.png')"/>
                </div>
                <div class="module-info">
                  <div class="title">模板名称：{{item.name}}</div>
                  <div class="desc">
                    模板简介：{{item.info}}
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="module-list-empty">
              <img class="empty-cover" :src="require('../assets/svg/empty.svg')"> </img>
            </div>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-sizes="[5, 10, 15, 20]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: 'module',
  data() {
    return {
      user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {},
      activeName: '1',
      moduleTypes: ['已安装', '登录模板', '前台模板', '功能模板'],
      total: 0,
      pageNum: 1,
      pageSize: 15,
      currentModule: {},
      moduleList: [],
      name:""
    }
  },
  created() {
    this.loadModules()
  },
  methods: {
    loadModules(){
      this.request.get("/module/page",{params:{
          pageNum:this.pageNum,
          pageSize:this.pageSize,
          type:this.activeName,
          name:this.name,
        }}).then(res=>{
          this.moduleList=res.data.records
          this.total=res.data.total
      })
    },
    changeModule(item){
      this.currentModule=item
    },
    useModule(){
      if (this.currentModule.id==null){
        this.$message.error("当前没有选择的模板")
        return
      }
      this.request.post("/module/moduleGenerate",{id:this.currentModule.id}).then(res=>{
        if (res.code==='200'){
          this.$message.success("模块安装成功")
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize
      this.loadModules()
    },
    handleCurrentChange(pageNum) {
      this.pageNum = pageNum
      this.loadModules()
    },
    reset(){
      this.name=""
      this.loadModules()
    }
  }
}
</script>

<style scoped lang="scss">
.module-container {
  .preview-wrap {
    background-color: #fff;
    padding: 12px;
    height: 280px;
    display: flex;
    border-radius: 6px;

    .notice-wrap {
      width: 20%;
      height: 100%;
      display: flex;
      flex-direction: column;
      .notice-content {
        flex: 1;
        background-color: #f1f1f1;
        padding: 12px;
        border-radius: 4px;
        font-size: 16px;
        text-align: justify;
        word-break: break-all;
        overflow-y: scroll;
        flex-shrink: 0;
        display: flex;
      }
      .notice-common-wrap {
        margin-bottom: 10px;
      }
    }

    .main-wrap {
      flex-shrink: 0;
      margin: 0 20px;
      border-radius: 4px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      height: 100%;
      .module-cover {
        width: 450px;
        height: 100%;
        border-radius: 4px;
        overflow: hidden;
        background-color: #f1f1f1;
        display: flex;
        align-items: center;
        justify-content: center;
        .el-image {
          height: 100%;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .info-wrap {
      flex: 1;
      font-size: 16px;
      line-height: 1.4;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title {
        margin-bottom: 15px;
      }
      .el-button {
        height: 40px;
        font-size: 16px;
      }
      .btn-submit {
        color: #fff;
        background-color: var(--color-primary);
        width: 200px;
      }
    }
    .module-info {
      font-size: 16px;
      background-color: #f1f1f1;
      flex: 1;
      border-radius: 4px;
      padding: 12px;
      border-left: 3px solid var(--color-primary);
      margin-top: 10px;
      .info-row {
        display: flex;
      }
      .info-item {
        padding: 4px 0;
        margin-right: 30px;
      }
    }
  }
  .module-wrap {
    margin-top: 30px;
    min-height: 54vh;

    .el-tabs {
      height: 100%;
      overflow: scroll;
    }
    .module-content-wrap {
      padding-left: 15px;
    }
    .search-wrap {
      width: 400px;
      display: flex;
      .el-button {
        margin-left: 10px;
      }
    }
    .module-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      gap: 20px;
      grid-auto-rows: 200px;
      margin-top: 15px;
      min-height: 35vh;

      .module-item {
        display: flex;
        border-radius: 4px;
        overflow: hidden;
        width: 100%;
        flex-direction: column;
        .module-cover {
          height: 65%;
          width: 100%;
          border-radius: 4px;
          overflow: hidden;
          flex-shrink: 0;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .module-info {
          font-size: 15px;
          line-height: 1.2;
          margin-top: 10px;
          text-align: justify;
          word-break: break-all;
          .desc {
            margin-top: 5px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* 设置最大显示行数 */
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .el-pagination {
      margin-top: 20px;
      text-align: center;
    }
  }
  .empty-cover {
    height: 100px!important;
    width: 100px!important;
  }
  .module-list-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 37vh;
  }
}
</style>
